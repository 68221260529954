export const imports = {
  'src/pages/advanced/composition.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-advanced-composition" */ 'src/pages/advanced/composition.mdx'
    ),
  'src/pages/advanced/override.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-advanced-override" */ 'src/pages/advanced/override.mdx'
    ),
  'src/pages/advanced/static-analysis.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-advanced-static-analysis" */ 'src/pages/advanced/static-analysis.mdx'
    ),
  'src/pages/concepts/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-concepts-index" */ 'src/pages/concepts/index.mdx'
    ),
  'src/pages/index/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-index-index" */ 'src/pages/index/index.mdx'
    ),
  'src/pages/motivation/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-motivation-index" */ 'src/pages/motivation/index.mdx'
    ),
  'src/pages/setup/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-setup-index" */ 'src/pages/setup/index.mdx'
    ),
  'src/pages/usage/css-in-js.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-usage-css-in-js" */ 'src/pages/usage/css-in-js.mdx'
    ),
  'src/pages/usage/css-modules.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-usage-css-modules" */ 'src/pages/usage/css-modules.mdx'
    ),
  'src/pages/usage/typescript.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-usage-typescript" */ 'src/pages/usage/typescript.mdx'
    ),
  'src/pages/migration/styled-components.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-migration-styled-components" */ 'src/pages/migration/styled-components.mdx'
    ),
}
